.cards {
  &__wrap {
    padding-bottom: 15px;
    &::-webkit-scrollbar {
      display: block;
      width: 12px;
    }
    &::-webkit-scrollbar-track {
      background-color: initial;
    }
    &::-webkit-scrollbar-thumb {
      background-color: rgb(185, 183, 183);
      border-radius: 20px;
      border: 3px solid rgb(185, 183, 183);
    }
  }
}

.portfolio-card {
  &__wrapp {
    padding-bottom: 15px;
    &::-webkit-scrollbar {
      display: block;
      width: 12px;
    }
    &::-webkit-scrollbar-track {
      background-color: initial;
    }
    &::-webkit-scrollbar-thumb {
      background-color: rgb(185, 183, 183);
      border-radius: 20px;
      border: 3px solid rgb(185, 183, 183);
    }
  }
}
