.table-wrapp {
  table {
    border-collapse: separate;
  }
}

.sorted {
  border: 1px solid #000;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  background-color: #fff;
}
