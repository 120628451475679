:focus {
  outline: 0 !important;
}

// Compass mixins
@import 'node_modules/compass-mixins/lib/compass/_reset.scss';
@import 'node_modules/compass-mixins/lib/compass/_css3.scss';

body {
  margin: 0;
  font-family: -apple-system, Inter, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

::-webkit-scrollbar {
  -webkit-appearance: none;
}

a:active,
a:hover,
a:focus,
button:active,
button:hover,
button:focus {
  outline: 0 !important;
  outline-offset: 0 !important;
  box-shadow: none !important;
}

.table-wrapp {
  &::-webkit-scrollbar-corner {
    background: rgba(0, 0, 0, 0);
  }
  &::-webkit-scrollbar {
    display: block;
    width: 12px;
  }
  &::-webkit-scrollbar-track {
    background-color: initial;
  }
  &::-webkit-scrollbar-thumb {
    background-color: rgb(185, 183, 183);
    border-radius: 20px;
    border: 3px solid rgb(185, 183, 183);
  }
}
