
.user-popup {
  &__header {
    display: flex;
    border: none;
  }
  &__body {
    display: flex;
    flex-direction: column;
    padding-top: 0;
  }
}

.exit {
  margin-top: 0px;
}
