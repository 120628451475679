.portfolio-table {
  &::-webkit-scrollbar {
    width: 12px; /* width of the entire scrollbar */
  }
  &::-webkit-scrollbar-track {
    background: #fff; /* color of the tracking area */
  }
  &::-webkit-scrollbar-thumb {
    background-color: rgb(185, 183, 183); /* color of the scroll thumb */
    border-radius: 20px; /* roundness of the scroll thumb */
    border: 3px solid rgb(185, 183, 183); /* creates padding around scroll thumb */
  }
  &::-webkit-scrollbar-track {
    background-color: initial;
  }
  &__input {
    border: none !important;
    text-align: right;
    padding: 5px !important;
    &:focus {
      box-shadow: none !important;
    }
  }
}
